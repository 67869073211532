// src/Pages/Login/Login.js
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Bg from '../../assets/bg.png';
import logo from '../../assets/logo.svg';
import { Paper, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from "@mui/material/Button";
import { Login_data, save_session_id } from './LoginAction/LoginActionApi';
import { useNavigate } from 'react-router-dom';
import loader from '../../assets/loader.gif';
import './Login.css';

const Login = ({ onLogin }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const LoadingData = useSelector((state) => state.login.ResponseDataLoading);
    const [multipleDeviceError, setMultipleDeviceError] = useState(false);

    useEffect(() => {
        const Token = sessionStorage.getItem("Token");
        let newErrors = {};
        if (Token === "none") {
            if (sessionStorage.getItem("multipleDevice")) {
                sessionStorage.setItem("Token", "null");
                // Show multiple device login error
                setMultipleDeviceError(true);
              }else{
                newErrors.email = 'Invalid email';
                newErrors.password = 'Invalid password';
                setErrors(newErrors);
            }
        } else if (Token && Token !== "null") {
            const sessionId = new Date().getTime().toString(); // Simple session ID, replace with a better method
            save_session_id(formData.email,Token,sessionStorage.getItem("ProductId"),sessionStorage.getItem("Username"),sessionId)
            onLogin(sessionId, sessionStorage.getItem('Role'));
            navigate('/');
            
        }
    }, [LoadingData, navigate, onLogin]);

    useEffect(() => {
        sessionStorage.setItem("Token", "null");
    }, []);

    const handleChange = (prop) => (event) => {
        setMultipleDeviceError(false);
        setFormData({ ...formData, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setFormData({ ...formData, showPassword: !formData.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let newErrors = {};
        if (!formData.email.includes('@')) {
            newErrors.email = 'Invalid email';
        }
        if (formData.password.length < 6) {
            newErrors.password = 'Password should be at least 6 characters';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            dispatch(Login_data(formData.email, formData.password, generateDeviceId()));
        }
    };

    const generateDeviceId = () => {
        const navigatorInfo = window.navigator;
      
        const deviceId = [
          navigatorInfo.language.toLowerCase(), // Normalize language,
          navigatorInfo.platform.toLowerCase(),
          navigatorInfo.hardwareConcurrency || 1
        ].join('::');
      
        return hashString(deviceId);
    }
      
    // Simple hash function to create a unique ID
    function hashString(input) {
        let hash = 0;
        for (let i = 0; i < input.length; i++) {
          const char = input.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash |= 0; // Convert to 32-bit integer
        }
        return 'device-' + Math.abs(hash).toString(16);
    }
      

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            backgroundImage: `url(${Bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        }}>
            <Paper elevation={3} sx={{
                width: ['90%', '60%', '40%', '30%'],
                padding: 4,
                borderRadius: 3,
                marginRight: '2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }} className='main-box'>
                <Box sx={{
                    width: '6rem',
                    height: '6rem',
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    mb: 3
                }} />
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        color: '#232323',
                        mb: 1,
                        textAlign: 'center'
                    }}
                    variant="h4"
                >
                    Sign in
                </Typography>
                {multipleDeviceError && (
                        <p style={{ textAlign: 'center', color: 'red' }}>
                        Multiple Device Login Not Allowed
                        </p>
                )}
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        color: '#969696',
                        mb: 3,
                        textAlign: 'center'
                    }}
                    variant="h6"
                >
                    Please login to continue to your account.
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange('email')}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type={formData.showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handleChange('password')}
                        error={Boolean(errors.password)}
                        helperText={errors.password}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        style={{
                            marginTop: '1.5rem',
                            borderRadius: '12px',
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            backgroundColor: '#0093FB',
                            color: '#ffffff',
                            width: '100%',
                            height: '3rem',
                            cursor: LoadingData ? 'not-allowed' : 'pointer',
                            opacity: LoadingData ? 0.6 : 1,
                            position: 'relative'
                        }}
                        disabled={LoadingData}
                    >
                        {LoadingData && (
                            <img src={loader} alt="Loader" style={{
                                width: '10%',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }} />
                        )}
                        {!LoadingData && 'Login'}
                    </Button>
                </form>
            </Paper>
        </Box>
    );
}

export default Login;
