// src/Pages/Login/Login.js
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Bg from '../../assets/bg.png';
import logo from '../../assets/logo.svg';
import { Paper, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { Login_data, qr_login, save_session_id } from './LoginAction/LoginActionApi';
import { useLocation, useNavigate } from 'react-router-dom';
import loader from '../../assets/loader.gif';
import './Login.css';

const CustomerLogin = ({onLogin}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const table_id = query.get('table_id');
    const product_id = query.get('product_id');
    const members = query.get('members');
    sessionStorage?.setItem("tableno", table_id)

    const [formData, setFormData] = useState({
        name: '',
        Phone_Number: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        Phone_Number: '',
    });

    const LoadingData = useSelector((state) => state.login.ResponseDataLoading);

    useEffect(() => {
        const Token = sessionStorage.getItem("Token");
        let newErrors = {};
        if (Token === "none") {
            newErrors.name = 'Invalid name';
            newErrors.Phone_Number = 'Invalid Phone_number';
            setErrors(newErrors);
        } else if (Token && Token !== "null") {
            const sessionId = new Date().getTime().toString(); // Simple session ID, replace with a better method
            save_session_id(sessionStorage.getItem("Email"),Token,sessionStorage.getItem("ProductId"),sessionStorage.getItem("Username"),sessionId)
            onLogin(sessionId, sessionStorage.getItem('Role'));
            navigate('/customerOrder');
            
        }
    }, [LoadingData, navigate, onLogin]);

    useEffect(() => {
        sessionStorage.setItem("Token", "null");
    }, []);

    const handleChange = (prop) => (event) => {
        setFormData({ ...formData, [prop]: event.target.value });
        setErrors({...errors, [prop]:''})
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        let newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Please Enter Name';
        }
        if (formData.Phone_Number.length !== 10) {
            newErrors.Phone_Number = 'Phone Number should be of 10 digit';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(qr_login(formData.name, table_id, product_id, formData.Phone_Number, members, generateDeviceId()));
        }
    };
    const generateDeviceId = () => {
        const navigatorInfo = window.navigator;
      
        const deviceId = [
          navigatorInfo.language.split('-')[0].toLowerCase(), // Normalize language,
          navigatorInfo.platform.toLowerCase(),
          navigatorInfo.hardwareConcurrency || 1
        ].join('::');
      
        return hashString(deviceId);
    }
      
    // Simple hash function to create a unique ID
    function hashString(input) {
        let hash = 0;
        for (let i = 0; i < input.length; i++) {
          const char = input.charCodeAt(i);
          hash = (hash << 5) - hash + char;
          hash |= 0; // Convert to 32-bit integer
        }
        return 'device-' + Math.abs(hash).toString(16);
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            backgroundImage: `url(${Bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        }}>
            <Paper elevation={3} sx={{
                width: ['90%', '60%', '40%', '30%'],
                padding: 4,
                borderRadius: 3,
                marginRight: '2rem',
                marginLeft: '2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }} className='main-box'>
                <Box sx={{
                    width: '6rem',
                    height: '6rem',
                    backgroundImage: `url(${logo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    mb: 3
                }} />
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        color: '#232323',
                        mb: 1,
                        textAlign: 'center'
                    }}
                    variant="h4"
                >
                    Welcome
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        color: '#969696',
                        mb: 3,
                        textAlign: 'center'
                    }}
                    variant="h6"
                >
                    Please Fill in your Details
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange('name')}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}>
                    <TextField
                        label="Code"
                        variant="outlined"
                        value="+91"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: '35%', marginRight: '0.5rem' }}
                        disabled
                    />
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        value={formData.Phone_Number}
                        onChange={handleChange('Phone_Number')}
                        error={Boolean(errors.Phone_Number)}
                        helperText={errors.Phone_Number}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: '65%'}}
                    />
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{
                            marginTop: '1.5rem',
                            borderRadius: '12px',
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            backgroundColor: '#0093FB',
                            color: '#ffffff',
                            width: '100%',
                            height: '3rem',
                            cursor: LoadingData ? 'not-allowed' : 'pointer',
                            opacity: LoadingData ? 0.6 : 1,
                            position: 'relative'
                        }}
                        disabled={LoadingData}
                    >
                        {LoadingData && (
                            <img src={loader} alt="Loader" style={{
                                width: '10%',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                            }} />
                        )}
                        {!LoadingData && 'Move Forward'}
                    </Button>
                </form>
            </Paper>
        </Box>
    );
}

export default CustomerLogin;
