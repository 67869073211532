import { LoginActionType } from '../LoginType/LoginActionType';
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";
import { TableData } from '../../Reserve/ReserveActions/ReserveActionApi';
import { OrderHistoryData, menudata } from '../../Order/OrderActions/OrderActionApi';
import { kothistory } from '../../KotHistory/KotHistoryActions/KotHistoryActionApi';
import { CategoryList } from '../../EditMenu/EditMenuActions/EditMenuApi';
import { get_users_data } from '../../EditUsers/EditUsersActions/EditUserActions';

export const Login_data=(email, password, deviceId)=>{
    
    const data={
        "email":email,
        "password":password,
        "deviceId":deviceId
    }
    return async (dispatch) => {
        dispatch({ type: LoginActionType.RESPONSE_DATA_LOADING });

        try {
                const response = await Axios.post(`${base_url}/login_auth`, data);
                const { email, product_id, access_token, username, role, reserve_layout_type, chef_limit, bartender_limit, user_limit} = response.data;
                if(role !== 'admin')
                {
                  const response1 = await Axios.get(`${base_url}/reset_counter?product_id=${product_id}&email=${email}`,{
                    headers: {
                      'Authorization': `Bearer ${access_token}`,
                    }});
                
                
                // Dispatching additional actions
                  await dispatch(TableData(product_id, email, access_token));
                  await dispatch(menudata(product_id,email,access_token));
                  await dispatch(OrderHistoryData(email, product_id, access_token));
                  await dispatch(CategoryList(email, product_id, access_token));
                  await dispatch(get_users_data(email,product_id,access_token));
                  await dispatch(kothistory(email, product_id, access_token));
                }

               
                // Storing data in session storage
                sessionStorage.setItem("Username", username);
                sessionStorage.setItem("Email", email);
                sessionStorage.setItem("Role", role);
                sessionStorage.setItem("ProductId", product_id);
                sessionStorage.setItem("Token", access_token);
                sessionStorage.setItem("chef_limit",chef_limit);
                sessionStorage.setItem("bartender_limit",bartender_limit);
                sessionStorage.setItem("user_limit",user_limit);
                sessionStorage.setItem("layout",reserve_layout_type);
                sessionStorage.setItem("multipleDevice", false);

                setTimeout(() => {
                  dispatch({
                    type: LoginActionType.RESPONSE_DATA_SUCCESS,
                });
                }, 2000); // 1000 milliseconds = 1 second
                
            
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle ERR_BAD_REQUEST (status code 400)
                sessionStorage.setItem("Token", "none");
                
                if(error.response.data.detail == "401: multiple device login detected"){
                  sessionStorage.setItem("multipleDevice", true);
                }
                
                dispatch({ type: LoginActionType.RESPONSE_DATA_SUCCESS });
                console.error('Bad Request:', error.response.detail);
                //dispatch({ type: LoginActionType.RESPONSE_DATA_SUCCESS });
                // You can add additional error handling logic here, such as displaying an error message or taking appropriate action
              } else {
                console.log(error);
              }
              
            }
    };
    
}

export const Logout_data = (email, product_id, token) => {
  const data = {
    email: email,
    product_id: product_id,
  };

  return async (dispatch) => {
    try {
      const response = await Axios.post(`${base_url}/logout`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Handle success (if needed, e.g., updating Redux state)
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle error (if needed)
    }
  };
};


export const qr_login =(name, table_id, product_id, contact, members, deviceId)=>{

  const data={
    "name":name,
    "table_id":table_id,
    "product_id":product_id,
    "contact":contact,
    "members":members,
    "deviceId": deviceId
  }

return async (dispatch) => {
    dispatch({ type: LoginActionType.RESPONSE_DATA_LOADING });

    try {
            const response = await Axios.post(`${base_url}/qr_login_auth`, data);
            const { email, product_id, access_token, username, role,chef_limit,bartender_limit,user_limit } = response.data;
            if(role !== 'admin')
            {
              const response1 = await Axios.get(`${base_url}/reset_counter?product_id=${product_id}&email=${email}`,{
                headers: {
                  'Authorization': `Bearer ${access_token}`,
                }});
            
            
            // Dispatching additional actions
              await dispatch(menudata(product_id,email,access_token));

        }
            // Storing data in session storage
            sessionStorage.setItem("Username", username);
            sessionStorage.setItem("Email", email);
            sessionStorage.setItem("Role", role);
            sessionStorage.setItem("ProductId", product_id);
            sessionStorage.setItem("Token", access_token);
            sessionStorage.setItem("chef_limit",chef_limit);
            sessionStorage.setItem("bartender_limit",bartender_limit);
            sessionStorage.setItem("user_limit",user_limit);
            sessionStorage.setItem("layout","layout2");

            setTimeout(() => {
              dispatch({
                type: LoginActionType.RESPONSE_DATA_SUCCESS,
            });
            }, 2000); // 1000 milliseconds = 1 second
            
        
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Handle ERR_BAD_REQUEST (status code 400)
            sessionStorage.setItem("Token", "none");
            dispatch({ type: LoginActionType.RESPONSE_DATA_SUCCESS });
            console.error('Bad Request:', error.response.detail);
            //dispatch({ type: LoginActionType.RESPONSE_DATA_SUCCESS });
            // You can add additional error handling logic here, such as displaying an error message or taking appropriate action
          } else {
            console.log(error);
          }
          
        }
};
}

export const save_session_id=(email,token,product_id,Username,sessionId)=>{
  const data={
    email:email,
    product_id:product_id,
    username:Username,
    current_session_id:sessionId
  }
  Axios.post(`${base_url}/save_session_id`,data,{
    headers: {
      'Authorization': `Bearer ${token}`,
    }
      })
      .then((response) =>{
        
    })
    .catch((error) => {
        console.error("Error in Session id:", error);
    });
}