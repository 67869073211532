import React,{useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import Navbar from '../../components/Navbar';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from '@mui/material/Divider';
import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TableData } from './ReserveActions/ReserveActionApi';
import { ReserveActionType } from './ReserveType/ReserveActionType';
import { OrderHistoryData, menudata } from '../Order/OrderActions/OrderActionApi';
import { kothistory } from '../KotHistory/KotHistoryActions/KotHistoryActionApi';
import { CategoryList } from '../EditMenu/EditMenuActions/EditMenuApi';
import { get_users_data } from '../EditUsers/EditUsersActions/EditUserActions';
import { socket_url } from '../../Redux/constants';


import { useDispatch, useSelector } from 'react-redux';


import './ReserveLayout.css';
import { Box, Paper, Typography } from '@mui/material';
import { CurrentOrder, customer_details, get_customer_details, get_order_details, reservetable, transfertable } from './ReserveActions/ReserveActionApi';
import { useNavigate } from 'react-router-dom';
import UserDetails from '../../components/UserDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReserveLayout= () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openPopupOrderDetails, setOpenPopupOrderDetails] = useState(false);
  const [openCustomerDetailsPopup,setCustomerDetailsPopup]=useState(false);
  const [popupData, setPopupData] = useState(null);
  const [popupOrderData,setPopupOrderData] = useState(null);
  const [Member, setMember] = React.useState();
  const [Value, setValue] = React.useState();
  const [status, setStatus] = React.useState();
  const username = sessionStorage?.getItem("Username");
  const product_id = sessionStorage?.getItem("ProductId");
  const email = sessionStorage?.getItem("Email");
  const token = sessionStorage?.getItem("Token");
  const floor =sessionStorage?.getItem("Floor");
  const [OrderId,setOrderId] = useState('');
  const [TransferFloor,setTransferFloor] = useState('');
  const [TransferTable,setTransferTable] = useState('');
  const [CustomerName,setCustomerName]=useState('');
  const [CustomerNumber,setCustomerNumber]=useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [TableData2 , setTableData] = useState('');
   const [ws, setWs] = useState(null);

  const TableData1 = useSelector((state) => state.reservetable.TableSchemaResponseDataMain);
  const OrderNumber = useSelector((state)=>state.reservetable.CurrentOrderId);
  const CustomerData = useSelector((state)=>state.reservetable.CustomerData)

  const handleChange = (event) => {
    setMember(event.target.value);
  };

  const handleTransferFloor = (event)=>{
    setTransferFloor(event.target.value)
  }

  const handleTransferTable = (event) =>{
    setTransferTable(event.target.value)
  }

  useEffect(()=>{
      if(!token || token === "undefined" || token === "null" || token === "none")
        {
            navigate("/")
        }
        else
        {
          dispatch(TableData(product_id, email, token));
          if(TableData1.length === 0)
            {
              dispatch(menudata(product_id,email,token));
              dispatch(get_users_data(email,product_id,token));
              dispatch(OrderHistoryData(email, product_id, token));
              dispatch(kothistory(email, product_id, token));
              dispatch(CategoryList(email,product_id,token));
            } 
        }
            // Initialize WebSocket connection
      const socket = new WebSocket(socket_url);
      setWs(socket);
  
  
      socket.onopen = () => {
      };
  
      socket.onmessage = (event) => {
        try {
          const message = event.data;
        // Handle incoming messages and update state as needed
        if (message === "reserve_table" || message === "edit_table_schema" || message === "transfer_table") {
          dispatch(TableData(product_id, email, token)); // Update KOT history on new message
        }
      }
      catch (error) {
        console.error("Error parsing message:", error);
      }
      };
  
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
  
      socket.onclose = () => {
      };
  
      return () => {
        socket.close(); // Clean up WebSocket connection on unmount
      }
    },[dispatch])
  useEffect(()=>{
    setOrderId(OrderNumber.current_order_id)
  },[OrderNumber])

  useEffect(()=>{
    if(TableData1)
    {
        setTableData(transformData(TableData1));
    }
  },[TableData1])

  const transformData = (data) => {
    const transformedData = { ...data }; // Copy the original data

    for (const topKey in data) {
      // Check if the value of the key is an object
      if (typeof data[topKey] === "object" && !Array.isArray(data[topKey]) && topKey !== "highest_table_id" && topKey !== "missing_table_ids") {
        const flattened = {};

        // Flattening the nested object
        for (const subKey in data[topKey]) {
          for (const key in data[topKey][subKey]) {
            flattened[key] = data[topKey][subKey][key];
          }
        }

        // Sorting the keys of the flattened object
        const sorted = Object.keys(flattened)
          .sort((a, b) => Number(a) - Number(b))
          .reduce((acc, key) => {
            acc[key] = flattened[key];
            return acc;
          }, {});

        // Replace the original key with the transformed object
        transformedData[topKey] = sorted;
      }
    }
    delete transformedData.highest_table_id;
    delete transformedData.missing_table_ids;
    return transformedData;
  };

  const handlePopupTransfer=()=>{
    setOpenPopup1(false);
    setOpenPopup2(true);
  }

  const handleCustomerDetailPopup =()=>{
    setOpenPopup1(false);
    setCustomerDetailsPopup(true);
  }

  const handleTileClick = async (number,value,status,floor) => {
    const items = Array.from({ length: number }, (_, index) => `${index + 1}`);
    setPopupData(items)
    setTransferFloor(floor);
    setValue(value)
    setMember(number)
    if(status === 'available')
    {
        setOpenPopup(true);
        setStatus('book');
    }
    else if(status === 'reserved')
    {
      await dispatch(CurrentOrder(product_id,email,value,token));
      setOrderId('');
      setStatus('unbook');
      setOpenPopup1(true);
    }
    dispatch(get_customer_details(product_id,email,value,token));
  };
  const handleOrderDetails= async()=>{
    if(OrderId !== 'None')
    {
      const data = await dispatch(get_order_details(product_id,email,OrderId,token));
      setPopupOrderData(data['data'])
    }
    setOpenPopup1(false);
    setOpenPopupOrderDetails(true);
  }
  const handlePopupClose = () => {
    setOpenPopup(false);
    setOpenPopup1(false);
    setOpenPopup2(false);
    setCustomerDetailsPopup(false);
    setOpenPopupOrderDetails(false);
    setPopupData(null);
  };

  const handlePopupReserveClose = async() => {
    await dispatch(reservetable(email,product_id,Value,Member,status,username,token))
    setOpenPopup(false);
    setOpenPopup1(false);
    setPopupData(null);
  };

  const handlePopupOrderClose = () => {
    if(CustomerData["customer_name"] === null)
    {
      handleCustomerDetailPopup();
      return;
    }
    sessionStorage.setItem("tableno",Value)
    sessionStorage.setItem("orderId",OrderId)
    navigate(`/Order`);
    setOpenPopup(false);
    setOpenPopup1(false);
    setPopupData(null);
  };

  const handlePopupTransferClose =()=>{
    if(TransferTable!== '')
    {
      dispatch(transfertable(email,product_id,Value,TransferTable,token))
      setTransferTable('');
      setOpenPopup2(false);
    }
  }

  const handleCustomerName=(event)=>{
      setCustomerName(event.target.value)
  }
  
  const handleCustomerNumber=(event)=>{
    setCustomerNumber(event.target.value)
    
  }

  const validateCustomerNumber = (number) => {
    const numberRegex = /^\d{10}$/;
    if (numberRegex.test(number)) {
      setErrorMessage('');
      return true;
    } else {
      if (!/^\d*$/.test(number)) {
        setErrorMessage('Customer number must contain only digits.');
      } else if (number.length !== 10) {
        setErrorMessage('Customer number must be exactly 10 digits.');
      }
      return false;
    }
  };

  const submitCustomerDetail= async(detail)=>{
    if(detail == "skip"){
        await dispatch(customer_details(email,product_id,Value,"Skipped","7666546003",token))
        sessionStorage.setItem("tableno",Value)
        sessionStorage.setItem("orderId",OrderId)
        setCustomerDetailsPopup(false)
        setOpenPopup(false);
        setOpenPopup1(false);
        setPopupData(null);
        setCustomerName('');
        setCustomerNumber('');
        navigate(`/Order`);
    }
    else{
    if(CustomerName !== '' && CustomerNumber !== '')
    {
      if(validateCustomerNumber(CustomerNumber))
      {
        await dispatch(customer_details(email,product_id,Value,CustomerName,CustomerNumber,token))
        sessionStorage.setItem("tableno",Value)
        sessionStorage.setItem("orderId",OrderId)
        setCustomerDetailsPopup(false)
        setOpenPopup(false);
        setOpenPopup1(false);
        setPopupData(null);
        setCustomerName('');
        setCustomerNumber('');
        navigate(`/Order`);
      }
    } 
    }
  }

  return (
    <>
    <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                <Navbar active="reserve" />
              </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"auto"}} className='container-main'>
              <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                <Grid item xs={12} md={12} lg={12}>
                    <UserDetails/>
                </Grid>
              </Grid>
              <Paper elevation={3} sx={{height:"auto",marginRight:"1.5%",borderRadius:7}} className='page'>
                <Grid container spacing={1} sx={{overflowX:"auto",paddingRight:"1%", paddingBottom: "1.8%"}}>
                  {TableData2 && Object?.keys(TableData2)?.map((item)=>(
                      <Grid item xs={12} md={12} lg={12}>
                      <Box sx={{
                       backgroundColor:"#E7E7E7",
                       borderRadius: 7,
                       marginTop: "1.8%",
                       marginLeft: "5%"
                      }}
                      className="table-data-layout">
                         <Box sx={{
                           backgroundColor:"#0093FB",
                           borderTopRightRadius: 20,
                           borderTopLeftRadius: 20,
                           paddingLeft: "5%",
                           height: "auto",
                           display: "flex", // Ensures flexbox layout
                           alignItems: "left", // Centers items vertically
                           justifyContent: "left", // Centers items horizontally
                           textAlign: 'left',
                         }}>
                             <Typography sx={{
                                       fontFamily: 'Poppins',
                                       fontWeight: '600',
                                       color: '#ffff',
                                       textAlign: 'left',
                                       }}
                                       noWrap
                                       component="div"
                                       className='seater'
                              >
                               {item}
                             </Typography>
                         </Box>
                         <Grid container spacing={1} sx={{overflowY:"auto",justifyContent: "left",alignItems: "left",padding:"2%"}}>
                          {Object?.keys(TableData2[item])?.map((value)=>(
                            <Grid item xs={3} md={2} lg={2}>
                              <div
                                key={value}
                                className="tile"
                                onClick={() => handleTileClick(16,value,TableData2[item][value],item)}
                                style={{
                                  backgroundColor: TableData2[item][value] === 'available' ? '#D9D9D9' : TableData2[item][value] === 'reserved'? '#058E6E':"#0AAE8E",
                                  color: TableData2[item][value] === 'available' ? '#7D7D7D' : '#FFFFFF',
                                  border: TableData2[item][value] === 'available' ? '1px solid #000000': TableData2[item][value] === 'reserved'? '1px solid #058E6E': '1px solid #0AAE8E',
                                  borderRadius: 7,
                                }}
                              >
                                {value}
                              </div>
                            </Grid>
                          ))}
                         </Grid>
                      </Box>
                   </Grid>
                  ))}
                  
                </Grid>
                      <Dialog
                  open={openPopup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "14rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                  {popupData && (
                    <DialogContent className="dialog-content">
                      {popupData && (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">No. of Members</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={Member}
                                onChange={handleChange}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"30%"}}
                              >
                                {popupData.map((item)=>(
                                  <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </DialogContent>
                  )}
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={handlePopupReserveClose}
                    >
                      Reserve
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openPopup1}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "500px", // Adjust the width as needed to make it square
                      height: "auto", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>
                    <DialogContent className="dialog-content-2">
                        <div>
                          <Box sx={{ fontSize: "30px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:500 }}>
                              <span className="label">What you want to do?</span>
                            </p>
                          </Box>
                        </div>
                    </DialogContent>
                    <Grid xs={12} sx={{paddingLeft:'5%',paddingRight:'5%'}}>
                      <Grid container spacing={1}>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions" style={{ justifyContent: 'center'}}>
                            <Button
                              variant="contained"
                              className="button-hover"
                              style={{
                                borderRadius: "12px", // Adjust the border radius as needed
                                fontFamily: "Poppins", // Set the font family
                                fontSize: "18px", // Adjust the font size as needed
                                backgroundColor: "rgba(0, 0, 0, 0.59)",
                                color: "#ffffff", // Set the text color
                                width: "90%" ,// Set the width
                                height: '4.6rem',
                              }}
                              onClick={handlePopupOrderClose}
                            >
                              Order
                            </Button>
                          </DialogActions>
                        </Grid>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions" style={{ justifyContent: 'center'}}>
                            <Button
                              variant="contained"
                              className="button-hover"
                              style={{
                                borderRadius: "12px", // Adjust the border radius as needed
                                fontFamily: "Poppins", // Set the font family
                                fontSize: "18px", // Adjust the font size as needed
                                backgroundColor: "rgba(0, 0, 0, 0.59)",
                                color: "#ffffff", // Set the text color
                                width: "90%" ,// Set the width
                                height: '4.6rem',
                              }}
                              onClick={handleOrderDetails}
                            >
                              Order Details
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                className="button-hover"
                                style={{
                                  borderRadius: "12px", // Adjust the border radius as needed
                                  fontFamily: "Poppins", // Set the font family
                                  fontSize: "18px", // Adjust the font size as needed
                                  backgroundColor: "rgba(0, 0, 0, 0.59)",
                                  color: "#ffffff", // Set the text color
                                  width: "90%",// Set the width
                                  height: '4.6rem',
                                }}
                                onClick={handlePopupTransfer}
                              >
                                Transfer Table
                              </Button>
                          </DialogActions>
                        </Grid>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions-bottom" style={{ justifyContent: 'center' }}>
                            <Button
                              variant="contained"
                              className="button-hover"
                              style={{
                                borderRadius: "12px", // Adjust the border radius as needed
                                fontFamily: "Poppins", // Set the font family
                                fontSize: "18px", // Adjust the font size as needed
                                backgroundColor: "rgba(0, 0, 0, 0.59)",
                                color: "#ffffff", // Set the text color
                                width: "90%" ,// Set the width
                                height: '4.6rem',
                              }}
                              onClick={handlePopupReserveClose}
                            >
                              UnReserve
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </Grid>
                </Dialog>

                <Dialog
                  open={openPopup2}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "20rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                  {TableData1 && (
                    <DialogContent className="dialog-content">
                      {TableData1&& (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Floor No.</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={TransferFloor}
                                onChange={handleTransferFloor}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"32%"}}
                              >
                                {Object?.keys(TableData1).map((item)=>{
                                  return (item !== "highest_table_id" && item !== "missing_table_ids") ?(
                                  <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>{item}</MenuItem>
                                ):null})}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                      {TableData1?.[TransferFloor]&& (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Table No.</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={TransferTable}
                                onChange={handleTransferTable}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"32%"}}
                              >
                               {Object?.keys(TableData1?.[TransferFloor]).map((item) =>
                                  Object?.keys(TableData1?.[TransferFloor]?.[item]).map((value) =>{
                                    return(TableData1?.[TransferFloor]?.[item]?.[value] === 'available') ? (
                                      <MenuItem key={value} value={value} sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'poppins' }}>{value}</MenuItem>
                                    ) : null}
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </DialogContent>
                  )}
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={handlePopupTransferClose}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openCustomerDetailsPopup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "19.5rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content">
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Customer Details</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'20%'}}>
                            <TextField
                              placeholder='Name'
                              value={CustomerName}
                              onChange={handleCustomerName}
                              //error={!!error}
                              //helperText={error}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                },
                              }}
                            />
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'20%',marginTop:"5%"}}>
                            <TextField
                              placeholder='Phone no.'
                              value={CustomerNumber}
                              onChange={handleCustomerNumber}
                              error={!!errorMessage}
                              helperText={errorMessage}
                              type='number'
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                },
                              }}
                            />
                          </Box>
                        </div>
                    </DialogContent>
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"50%"// Set the background color
                      }}
                      onClick={()=>submitCustomerDetail('save')}
                    >
                      Save Details
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        textAlign: "center" ,
                        width:"50%"// Set the background color
                      }}
                      onClick={()=>submitCustomerDetail("skip")}
                    >
                      Skip Details
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
            open={openPopupOrderDetails}
            onClose={handlePopupClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <div style={{ position: "relative" }}>
                <IconButton
                  aria-label="close"
                  onClick={handlePopupClose}
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>

              <DialogContent className="dialog-content">
                  <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
  <p style={{ fontFamily: "Poppins", fontWeight: 300, marginRight: "10px" }}>IN TIME: {popupOrderData?.order_in_time ? popupOrderData?.order_in_time: "---"} </p>
  <p style={{ fontFamily: "Poppins", fontWeight: 300 }}>
    OUT TIME: {popupOrderData?.order_complete_time ? popupOrderData?.order_complete_time : "---"}
  </p>
</div>

                    <Box sx={{ fontSize: "25px", color: "#0093FB" }}>
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                        <span className="label">ORDER DETAILS: </span>
                        <span className="value" style={{ marginRight: "20px" }}>
                          {OrderId}
                        </span>
                      </p>
                    </Box>
                    <table className="table">
                      <thead>
                        <tr>
                        <th> Table no.</th>
                          <th>Item Id</th>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {popupOrderData && Object.entries(popupOrderData?.cart).map(([itemId, cartItem], index) => (
                          <tr key={index}>
                            <td>{popupOrderData.table_id}</td>
                            <td>{itemId}</td>
                            <td>{cartItem[0]}</td>
                            <td>{cartItem[1]}</td>
                            <td>{cartItem[2]}</td>
                            <td>{cartItem[1] * cartItem[2]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "25px",
                        fontFamily: "Poppins",
                      }}
                    >
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>TOTAL</p>
                      <p style={{ fontSize: "25px", fontFamily: "Poppins", fontWeight: 600 }}>
                        RS {popupOrderData?.grand_total.toLocaleString()}
                      </p>
                    </div>
                  </div>
              </DialogContent>
          </Dialog>

              </Paper>
            </Grid>
    </Grid>
    </>
  );
};

export default ReserveLayout;